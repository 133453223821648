import "./styles.css";

function isFieldError(array, val) {
  return array.includes(val);
}

const Select = ({
  label,
  req,
  name,
  options,
  value,
  errors,
  autocomplete = name,
  update = null,
}) => {
  return (
    <div className="select">
      <label htmlFor={name} className={!label ? "hidden" : ""}>
        {`${label}`} {req ? <span className="error">*</span> : null}
      </label>
      <select
        name={name}
        id={name}
        defaultValue={value || ""}
        autoComplete={autocomplete}
        onChange={(e) => (update ? update(name, e.target.value) : null)}
        className={isFieldError(errors, name) ? "error-border" : ""}
      >
        {options.map((item, index) => {
          return (
            <option key={index} value={item.value}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default Select;
