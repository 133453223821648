import { useState, useEffect, useRef } from "react";
import { API_URL } from "./utils";

import Alert from "./shared/Alert";

function fmtTime(iso) {
	const d = new Date(`${iso}-04:00`);
	const hr = d.getHours();
	const min = d.getMinutes();
	return hr > 12
		? `${hr - 12}:${min} pm`
		: hr === 12
		? `${hr}:${min} pm`
		: `${hr}:${min} am`;
}

const TeeTime = ({ data }) => {
	return (
		<td className="center" colSpan="2">
			{data.tee === "10" ? `${fmtTime(data.time)}*` : fmtTime(data.time)}
		</td>
	);
};

const Thru = ({ round, data }) => {
	return (
		<>
			<td className="center">
				{round === data.currentRnd ? data.currentToPar : "-"}
			</td>
			<td className="center">{round === data.currentRnd ? data.thru : "-"}</td>
		</>
	);
};

function Leaderboard() {
	const [details, setDetails] = useState({
		updated: "",
		round: "",
		leaderboard: [],
	});
	const [alert, setAlert] = useState({ type: "working", text: "" });

	const initRef = useRef(false);

	useEffect(() => {
		if (initRef.current) return;

		initRef.current = true;
		init();

		// eslint-disable-next-line
	}, []);

	const init = async () => {
		const url = new URL(`${API_URL}/`);
		const params = {
			f: `cdn`,
			f2: `${encodeURIComponent("/k-api/?f=leaderboardInit")}`,
			q: `${encodeURIComponent(`year=2024`)}`,
			x: `328`, // WPE cache is 10 minutes ${Math.round(Date.now() / 1000)}
		};
		url.search = new URLSearchParams(params);

		try {
			const response = await fetch(url, {
				method: "GET",
				cache: "no-store",
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setDetails({
					updated: json.updated,
					round: json.round,
					leaderboard: json.leaderboard,
				});
				setAlert({});
			}
		} catch (error) {
			setAlert({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{alert.type ? (
				<Alert data={alert} />
			) : (
				<table className="leaderboard">
					<thead>
						<tr>
							<th className="smtext">Pos</th>
							<th className="maxwidth left smtext">Player</th>
							<th className="smtext">{`R${details.round}`}</th>
							<th className="smtext">Thru</th>
							<th className="smtext">Tot</th>
						</tr>
					</thead>
					<tbody>
						{details.leaderboard.map((item, index) => {
							return (
								<tr key={index}>
									<td className="center">{item.currentPos}</td>
									<td className="maxwidth">{item.player}</td>
									<>
										{details.round === item.currentRnd && item.time ? (
											<TeeTime round={details.round} data={item} />
										) : (
											<Thru round={details.round} data={item} />
										)}
									</>
									<td className="center">{item.tournamentToPar}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)}
		</>
	);
}

export default Leaderboard;
