import "./styles.css";

function isFieldError(array, val) {
  return array.includes(val);
}

const Textarea = ({
  type,
  label,
  note = "",
  req,
  name,
  value,
  errors,
  autocomplete = name,
  placeholder = "",
}) => {
  return (
    <div className="textarea" name={name}>
      <label htmlFor={name} className={!label ? "hidden" : ""}>
        {`${label}`} {req ? <span className="error">*</span> : null}
      </label>
      <textarea
        name={name}
        id={name}
        defaultValue={value || ""}
        autoComplete={autocomplete}
        placeholder={placeholder}
        className={isFieldError(errors, name) ? "error-border" : ""}
      />
      <small className={note ? "" : "hidden"}>{note}</small>
    </div>
  );
};

export default Textarea;
