import { useState, useRef, createRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { API_URL, RECAPTCHA_KEY } from "./utils";

import Select from "./shared/Select";
import Input from "./shared/Input";
import Textarea from "./shared/Textarea";
import Checkbox from "./shared/Checkbox";
import Msg from "./shared/Msg";
import Submit from "./shared/Submit";

import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons";

function Contact() {
  const [names, setNames] = useState({
    champ: false,
    paint: false,
  });
  const [msg, setMsg] = useState({});
  const [errors, setErrors] = useState([]);

  const formElement = useRef();
  const recaptchaRef = createRef();

  const handleUpdate = (name, value) => {
    if (name === "champ" || name === "paint") {
      const newValue = !value;

      setNames((names) => ({ ...names, [name]: newValue }));
    } else {
      setNames((names) => ({ ...names, [name]: value ? value : "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors([]);
    setMsg({
      type: "working",
      text: "",
    });

    let recaptcha = names.recaptcha
      ? names.recaptcha
      : await recaptchaRef.current.executeAsync();

    const url = new URL(`${API_URL}/?f=contact`);
    const formData = new FormData(formElement.current);
    formData.append("champ", names.champ ? "1" : "0");
    formData.append("paint", names.paint ? "1" : "0");
    formData.append("recaptcha", recaptcha);

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const json = await response.json();
      if (json && json.resp === 1) {
        formElement.current.reset();
        setNames({
          champ: false,
          paint: false,
        });

        setMsg({
          type: "success",
          text: json.text,
        });
      } else {
        if (json.fields[0] === "recaptcha") {
          handleUpdate("recaptcha", "");
          recaptchaRef.current.reset();
        } else {
          handleUpdate("recaptcha", recaptcha);
        }
        setErrors(json.fields);
        setMsg({
          type: "error",
          text: json.text,
        });
      }
    } catch (error) {
      console.log(error);

      setMsg({
        type: "error",
        text: "An error has occurred.",
      });
    }
  };

  return (
    <form
      method="post"
      action="/"
      onSubmit={(e) => handleSubmit(e)}
      ref={formElement}
    >
      <div className="form-row-2">
        <Select
          name="subject"
          label="Subject"
          req={true}
          value={""}
          options={[
            {
              value: "",
              name: "--",
            },
            {
              value: "tickets",
              name: "Tickets",
            },
            {
              value: "volunteers",
              name: "Volunteers",
            },
            {
              value: "sponsorship",
              name: "Sponsorship",
            },
            {
              value: "other",
              name: "Other",
            },
          ]}
          update={handleUpdate}
          errors={errors}
        />

        <div className="empty" />
      </div>

      <div className="form-row-2">
        <Input
          type="text"
          label="Name"
          req={true}
          name="name"
          value=""
          errors={errors}
          autocomplete="name"
        />
        <Input
          type="text"
          label="Email Address"
          req={true}
          name="email"
          value=""
          errors={errors}
          autocomplete="email"
        />
      </div>

      <div className="form-row-1">
        <Textarea
          label="Question / Comments"
          req={true}
          name="comments"
          value=""
          errors={errors}
          autocomplete="off"
        />
      </div>

      <div className="form-row-1">
        <Checkbox
          label="Opt-In to receive the Valspar Championship newsletter"
          name="champ"
          checked={names.champ}
          click={handleUpdate}
        />
        <Checkbox
          label="Opt-In to receive the Valspar Paint newsletter"
          name="paint"
          checked={names.paint}
          click={handleUpdate}
        />
      </div>

      <div className="form-row-1" style={{ marginTop: "15px" }}>
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={RECAPTCHA_KEY}
          badge="inline"
        />
      </div>

      <div className="msg-submit">
        {msg.type && <Msg data={msg} />}

        <div
          className={
            msg.type === "working" || msg.type === "success" ? "hidden" : ""
          }
        >
          <Submit name="Submit Inquiry" icon={faChevronCircleRight} />
        </div>
      </div>

      <p>
        We will not share your email address with third-parties. For more
        information on how we use your information, see our{" "}
        <a href="/privacy-policy/">Privacy Policy</a>.
      </p>
    </form>
  );
}

export default Contact;
