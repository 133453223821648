import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";

import Alert from "./shared/Alert/index";
import Contact from "./Contact";
import Leaderboard from "./Leaderboard";

/* uncomment for testing locally
window.vcformSettings = {
  id: "leaderboard", // contact, leaderboard
};
*/

if (document.getElementById("vcform-root")) {
	const root = ReactDOM.createRoot(document.getElementById("vcform-root"));

	let element = (
		<Alert data={{ type: "error", text: "An error has occurred." }} />
	);

	if (window.vcformSettings) {
		const { id } = window.vcformSettings;

		if (id === "contact") {
			element = <Contact />;
		} else if (id === "leaderboard") {
			element = <Leaderboard />;
		}
	}

	root.render(<React.StrictMode>{element}</React.StrictMode>);
}
